import Image from 'next/legacy/image';

export default () => (
  <div style={{position: 'absolute', top: 0, width: '100%', height: '100%'}}>
    <Image src={'/CharityBorder.png'} layout="fill"  />
    <div style={{position: 'absolute', top: 0, width: '100%', height: '8%'}}>
      <div style={{alignSelf: 'center', width: '73%', height: '100%'}}>
        <Image src={'/icons/CharityLogo.svg'} layout="fill"  />
      </div>
    </div>
  </div>
)
