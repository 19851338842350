import { ChangeEvent, useRef, useEffect } from 'react';

import styles from './Dropdown.module.scss';

export type DropdownSearchInputProps = {
  onSearch: (search: string) => void;
};

export const DropdownSearchInput = ({ onSearch }: DropdownSearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onSearch(event.target.value);
  };

  return <input ref={inputRef} type="text" className={styles.searchInput} onChange={onChange} />;
};
