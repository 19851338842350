import Image from 'next/legacy/image';

import cx from 'classnames';
import styles from './NextImage.module.scss';

export type NextIconProps = {
  alt?: string;
  customClass?: string;
  height?: number;
  id?: string;
  onClick?: (event?: any) => void;
  size?: number;
  src: string;
  width?: number;
};

export const NextIcon = ({
  alt = 'icon',
  customClass,
  height,
  id,
  onClick,
  size = 24,
  src = '/',
  width,
}: NextIconProps) => {
  return (
    <div className={cx(styles.iconContainer, customClass, { [styles.clickable]: !!onClick })} onClick={onClick}>
      <Image id={id} src={src} alt={alt} height={height || size} width={width || size} />
    </div>
  );
};
