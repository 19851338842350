import Image from "next/image";

import styles from './Dropdown.module.scss'

export const DropdownCaret = ({isOpen}: {isOpen: boolean}) => {
  return (
    <div className={styles.caret}>
      {isOpen ? (
        <Image src="/icons/arrows/CaretUpDark.svg" width={24} height={24} alt="Close" />
      ) : (
        <Image src="/icons/arrows/CaretDown.svg" width={24} height={24} alt="Open" />
      )}
    </div>
  )
}
