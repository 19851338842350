import {MouseEvent, MutableRefObject, ReactElement, useEffect, useRef, useState} from 'react';
import Image from 'next/legacy/image';
import { DropdownOption } from 'types';
import { openDropdownUp } from 'utils/services';

import cx from 'classnames';
import styles from './Dropdown.module.scss';
import {NextIcon} from "../NextImage";

export type DropdownMenuProps = {
  className?: string;
  currentValue: string | number | null;
  isOpen: boolean;
  isThin?: boolean;
  items: DropdownOption[];
  label?: string | ReactElement;
  shiftRight?: boolean;
  trackChanges: (value: string | number | null) => void;
  toggleOpen?: () => void;
};

export const DropdownOptionList = ({
  className,
  currentValue,
  isOpen,
  isThin,
  items,
  label,
  shiftRight,
  trackChanges,
  toggleOpen,
}: DropdownMenuProps) => {
  const [toLowSpaceBottom, setToLowSpaceBottom] = useState(false);
  const [showIt, setShowIt] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    setToLowSpaceBottom(openDropdownUp(menuRef as MutableRefObject<any>));
    setShowIt(isOpen);
  }, [isOpen]);

  const handleClick = (event: MouseEvent, value: number | string | null) => {
    event.preventDefault();
    event.stopPropagation();
    trackChanges && trackChanges(value);
    toggleOpen && toggleOpen();
  };

  return (
    <>
      <div
        ref={menuRef}
        className={cx(
          styles.itemWrapper,
          {
            [styles.wrapperClosed]: !isOpen,
            [styles.ThinIndent]: isThin,
            [styles.openUp]: toLowSpaceBottom && isOpen,
            [styles.openDown]: !toLowSpaceBottom && isOpen,
            [styles.openFarDown]: !toLowSpaceBottom && !!label && isOpen,
            [styles.shiftRight]: shiftRight && isOpen,
            [styles.shouldShow]: showIt,
            [styles.isThin]: isThin,
          },
          className,
        )}
      >
        {items.map((item, index) => {
          const caption = item.label || item.title || item.name || (item.first_name && `${item.first_name} ${item.last_name || ''}`);
          const value = typeof item.value !== 'undefined' ? item.value : typeof item.id !== 'undefined' ? item.id : '';

          return (
            <div
              key={`${index}${item.title || item.label || item.name}`}
              className={cx(styles.item, { [styles.active]: currentValue && value === currentValue })}
              onMouseDown={(event) => handleClick(event, value)}
            >
              <p className={styles.optionItem}>{item.url && <span><NextIcon src={item.url}/></span>}{caption}</p>

              {(item.value || item.id) === currentValue && !!currentValue && (
                <Image src="/icons/checkbox/Tick.svg" width="16" height="11" alt="Checked" />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
