import Image from 'next/legacy/image';
import { ReactElement } from 'react';

import cx from 'classnames';
import styles from './NextImage.module.scss';
import CharityLogo from '../CharityLogo';

export type NextBackgroundImageProps = {
  alt?: string;
  children?: ReactElement | null;
  customClass?: string;
  layout?: 'intrinsic' | 'fixed' | 'responsive' | 'fill';
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  src: string;
  isCharity?: boolean
};

export const NextBackgroundImage = ({
  alt = 'image',
  children,
  customClass,
  layout = 'fill',
  objectFit = 'cover',
  src = '/',
  isCharity,
}: NextBackgroundImageProps) => {
  return (
    <div className={cx(styles.container, customClass)}>
      {children && children}
      <Image src={src} alt={alt} layout={layout} objectFit={objectFit} />
      {isCharity && <CharityLogo />}
    </div>
  );
};
